<template>
  <div class="full-page-container">
    <i class="icon icon-close" @click="goBack()"></i>
    <div class="error-container">
      <img class="error-code-image" src="@/assets/img-404.png" alt="404에러 이미지" />
      <div ref="lottieContainer" class="error-icon" style="width:80px;height:80px;"></div>
      <!--      <lottie-player class="error-icon" autoplay loop :src="lottieGhost" style="width:80px;height:80px;" />-->
      <img class="error-code-text" src="@/assets/img-404-text.png" alt="요청하신 페이지를 찾을 수 없습니다" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.full-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .icon {
    position: absolute;
    top: 14px;
    right: 18px;
  }
}

.error-container {
  position: relative;

  img {
    display: block;
    margin: auto;
  }

  .error-code-image {
    width: 216px;
  }

  .error-icon {
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -40px;
  }

  .error-code-text {
    width: 135px;
    margin-top: 50px;
  }
}
</style>

<script>
import lottie from "lottie-web";
const animationData = require("@/assets/lottie/ghost.json");

export default {
  name: "NotFound",
  methods: {
    goBack() {
      this.$router.back();
    }
  },
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.lottieContainer,
      renderer: "svg",
      loop: true,
      autoplay: true,
      name: "lottieGhost",
      animationData: animationData
    });
  }
};
</script>
